import React from "react";
import * as rdd from "react-device-detect";

import { Block, TrackedLink } from "..";
import { AndroidLogo, AppleLogo } from "../../svgs";

import "./get-the-app-block.scss";

import DeviceImg1 from "../../images/download-ios.svg";
import DeviceImg2 from "../../images/download-android.svg";

const APP_STORE_LINK =
  "itms-appss://apps.apple.com/us/app/partie/id1520305781?uo=4";
const APP_STORE_BROWSER = "https://apps.apple.com/us/app/partie/id1520305781";
const ANDROID_DOWNLOAD_LINK =
  "https://play.google.com/store/apps/details?id=com.partie";

const GetTheAppBlock = () => {
  return (
    <Block className="get-the-app-block" lazy>
      <h3 className="partie-block__title">Get the App</h3>
      <div className="get-the-app-block__downloads">
        <div className="get-the-app-block__download-column">
          <TrackedLink
            type="get-the-app-block__download"
            to={rdd.isMacOs || rdd.isIOS ? APP_STORE_LINK : APP_STORE_BROWSER}
            rel="noreferrer"
            target="_blank"
            children={<>
              <div className="get-the-app-block__download-brand">
                <AppleLogo />
                <h4 className="get-the-app-block__download-cta">
                  Download for iOS
                </h4>
              </div>
              <img src={DeviceImg1} alt="Partie on iOS" loading="lazy" />
            </>}
          />
        </div>
        <div className="get-the-app-block__download-column">
          <TrackedLink
            type="get-the-app-block__download"
            to={ANDROID_DOWNLOAD_LINK}
            rel="noreferrer"
            target="_blank"
            children={<>
              <div className="get-the-app-block__download-brand">
                <AndroidLogo />
                <h4 className="get-the-app-block__download-cta">
                  Download for Android
                </h4>
              </div>
              <img src={DeviceImg2} alt="Partie on Android" loading="lazy" />
            </>}
          />
        </div>
      </div>
    </Block >
  );
};

export default GetTheAppBlock;
