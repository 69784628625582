import React from 'react';

const Logo = () => (
  <svg width="65" height="18" viewBox="0 0 65 18" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M29.2624 8.81633C29.2624 11.1932 27.5979 12.8963 25.2598 12.8963C24.5737 12.8963 23.9383 12.7184 23.392 12.3879V15.4003H20.7871V4.99053H22.5787L22.9091 5.60063C23.5317 5.05409 24.345 4.73633 25.2598 4.73633C27.5851 4.73633 29.2624 6.4268 29.2624 8.81633ZM26.6067 8.81633C26.6067 7.81222 25.8825 7.06231 24.8913 7.06231C23.9002 7.06231 23.176 7.81222 23.176 8.81633C23.176 9.82044 23.9129 10.5703 24.9041 10.5703C25.8825 10.5703 26.6067 9.82044 26.6067 8.81633Z" />
    <path
      d="M38.3224 4.97683V12.6284H36.2894L36.0606 12.0819C35.438 12.5903 34.6375 12.8826 33.748 12.8826C31.41 12.8826 29.7455 11.1795 29.7455 8.80263C29.7455 6.43851 31.41 4.74805 33.748 4.74805C34.6502 4.74805 35.4507 5.05309 36.0861 5.57422L36.3529 4.97683H38.3224ZM35.8192 8.81534C35.8192 7.82394 35.0949 7.07403 34.1038 7.07403C33.1127 7.07403 32.3884 7.82394 32.3884 8.81534C32.3884 9.81945 33.1127 10.5566 34.1038 10.5566C35.0949 10.5566 35.8192 9.80674 35.8192 8.81534Z" />
    <path
      d="M44.6757 4.95117V7.36613H43.6592C42.7316 7.36613 42.3377 7.77285 42.3377 8.75155V12.6409H39.7074V4.9893H41.4736L41.8803 5.84089C42.4139 5.21809 43.0747 4.95117 43.9896 4.95117H44.6757Z" />
    <path
      d="M51.1812 10.3282V12.6288H49.3642C47.6742 12.6288 46.6449 11.5993 46.6449 9.89609V7.02357H45.2472V6.41348L48.6653 2.76562H49.2117V5.00263H51.1304V7.03628H49.2752V9.46394C49.2752 10.0105 49.6056 10.341 50.1647 10.341H51.1812V10.3282Z" />
    <path d="M52.1214 4.99106H54.7517V12.6427H52.1214V4.99106ZM52.1468 1.80078H54.739V4.03779H52.1468V1.80078Z" />
    <path
      d="M64.0022 8.73914C64.0022 8.96793 63.9768 9.19671 63.9514 9.43821H58.2715C58.4494 10.3152 58.9958 10.7728 59.8344 10.7728C60.4443 10.7728 60.9653 10.5059 61.2194 10.061H63.837C63.3033 11.8023 61.7531 12.8954 59.8344 12.8954C57.4456 12.8954 55.6794 11.1541 55.6794 8.81541C55.6794 6.47671 57.4329 4.74812 59.8344 4.74812C62.2995 4.7354 64.0022 6.50213 64.0022 8.73914ZM58.3223 7.97653H61.41C61.1813 7.21391 60.6222 6.79447 59.8344 6.79447C59.072 6.80718 58.5384 7.22662 58.3223 7.97653Z" />
    <path
      d="M13.3384 7.40628V10.6012C13.3384 10.6012 13.3329 10.9223 13.0173 10.9223H9.3307V13.7906C9.3307 13.7906 9.3307 14.1117 9.00964 14.1117H5.323V16.9799C5.323 17.1571 5.17354 17.3011 5.00194 17.3011H0.988703C0.811567 17.3011 0.667644 17.1516 0.667644 16.9799V13.785C0.667644 13.785 0.678716 13.4639 0.988703 13.4639H4.67534V10.9223H0.988703C0.988703 10.9223 0.700857 10.9334 0.667644 10.6012V7.40628C0.667644 7.40628 0.667645 7.08512 0.988703 7.08512C1.30976 7.08512 4.67534 7.08512 4.67534 7.08512V4.52698H0.977632C0.667645 4.52698 0.662109 4.21137 0.662109 4.21137V1.01646C0.662109 0.70085 0.983168 0.695312 0.983168 0.695312H4.99087C5.30639 0.695312 5.31192 1.01646 5.31192 1.01646V3.88468H8.99857C8.99857 3.88468 9.31962 3.90683 9.31962 4.20583V7.07405H13.0063C13.0118 7.08512 13.3384 7.09066 13.3384 7.40628ZM8.67751 10.2745V7.73296H5.31192V10.2745H8.67751Z" />
  </svg>
)

export default Logo;