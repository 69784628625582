import React from 'react';

const LogoColorBlock = ({ backgroundFill = '#6E4AFF' }) => (
  <svg width="250" height="118" viewBox="0 0 250 118" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M250 0H0V118H250V0Z" fill={backgroundFill} />
    <path d="M101.284 59.0266C101.284 63.3816 98.233 66.5023 93.9478 66.5023C92.6902 66.5023 91.5257 66.1763 90.5243 65.5708V71.0903H85.7501V52.0166H89.0338L89.6393 53.1344C90.7805 52.133 92.271 51.5508 93.9478 51.5508C98.2097 51.5508 101.284 54.6482 101.284 59.0266ZM96.4164 59.0266C96.4164 57.1867 95.089 55.8127 93.2724 55.8127C91.4559 55.8127 90.1284 57.1867 90.1284 59.0266C90.1284 60.8664 91.4792 62.2404 93.2957 62.2404C95.089 62.2404 96.4164 60.8664 96.4164 59.0266Z" fill="#F8F6FF" />
    <path d="M117.889 51.9934V66.0134H114.163L113.743 65.012C112.602 65.9436 111.135 66.4792 109.505 66.4792C105.22 66.4792 102.169 63.3585 102.169 59.0034C102.169 54.6717 105.22 51.5742 109.505 51.5742C111.158 51.5742 112.626 52.1332 113.79 53.088L114.279 51.9934H117.889ZM113.301 59.0267C113.301 57.2102 111.974 55.8361 110.157 55.8361C108.34 55.8361 107.013 57.2102 107.013 59.0267C107.013 60.8665 108.34 62.2173 110.157 62.2173C111.974 62.2173 113.301 60.8433 113.301 59.0267Z" fill="#F8F6FF" />
    <path d="M129.532 51.9492V56.3741H127.669C125.969 56.3741 125.247 57.1194 125.247 58.9126V66.0391H120.426V52.0191H123.663L124.408 53.5795C125.386 52.4383 126.597 51.9492 128.274 51.9492H129.532Z" fill="#F8F6FF" />
    <path d="M141.457 61.7984V66.0137H138.127C135.03 66.0137 133.143 64.1273 133.143 61.0066V55.7432H130.581V54.6254L136.846 47.9414H137.848V52.0403H141.364V55.7665H137.964V60.2147C137.964 61.2162 138.57 61.8217 139.594 61.8217H141.457V61.7984Z" fill="#F8F6FF" />
    <path d="M143.18 52.0174H148.001V66.0374H143.18V52.0174ZM143.226 46.1719H147.977V50.2707H143.226V46.1719Z" fill="#F8F6FF" />
    <path d="M164.957 58.8871C164.957 59.3063 164.911 59.7255 164.864 60.168H154.454C154.78 61.7749 155.782 62.6133 157.319 62.6133C158.437 62.6133 159.391 62.1243 159.857 61.3092H164.655C163.677 64.4998 160.835 66.5026 157.319 66.5026C152.94 66.5026 149.703 63.312 149.703 59.0268C149.703 54.7417 152.917 51.5743 157.319 51.5743C161.837 51.5511 164.957 54.7882 164.957 58.8871ZM154.547 57.4898H160.206C159.787 56.0924 158.763 55.3239 157.319 55.3239C155.921 55.3472 154.943 56.1157 154.547 57.4898Z" fill="#F8F6FF" />
  </svg>
);

export default LogoColorBlock;
